import React, { useState, useContext } from 'react';
import Context from '../../Context';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  TextField,
  Alert,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import CustomLoadingButton from '../common/CustomLoadingButton';

const UserSignIn = () => {
  const context = useContext(Context.Context);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const onChange = event => {
    const { name, value } = event.target;

    if (name === 'emailAddress') {
      setEmailAddress(value);
    }

    if (name === 'password') {
      setPassword(value);
    }
  };

  const submit = async event => {
    event.preventDefault();
    setIsLoading(true);

    const { from } = location.state || { from: { pathname: '/' } };

    try {
      const response = await context.actions.signIn(emailAddress, password);

      if (response.resetPassword === true) {
        localStorage.setItem('userEmail', emailAddress);
        navigate('/update-password');
        return;
      }
      if (response && response.id) {
        navigate(from);
      } else {
        const errorMessage =
          response.message === 'Access Denied'
            ? 'Email ou senha incorretos. Por favor, tente novamente.'
            : response.message || 'Não autorizado';
        setErrors(errorMessage);
      }
    } catch (error) {
      console.error(error);
      navigate('/error');
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}
    >
      <div
        className="form--centered"
        style={{
          maxWidth: '400px',
          width: '100%',
          padding: '20px',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: '#370144',
            marginBottom: '20px'
          }}
        >
          <LockIcon style={{ fontSize: '32px', color: '#370144' }} />
          Entrar
        </Typography>
        {errors && (
          <Alert
            severity="error"
            style={{
              marginBottom: '10px',
              fontSize: '12px',
              padding: '8px'
            }}
          >
            {errors}
          </Alert>
        )}
        <form onSubmit={submit}>
          <div>
            <TextField
              required
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              name="emailAddress"
              type="email"
              value={emailAddress}
              onChange={onChange}
              size="small"
              InputProps={{
                style: {
                  fontSize: '14px'
                }
              }}
              InputLabelProps={{
                style: {
                  fontSize: '14px'
                }
              }}
            />
          </div>
          <div>
            <TextField
              required
              label="Senha"
              variant="outlined"
              fullWidth
              margin="normal"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={onChange}
              size="small"
              InputProps={{
                style: {
                  fontSize: '14px'
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              InputLabelProps={{
                style: {
                  fontSize: '14px'
                }
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '15px' }}>
            <CustomLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: '#370144',
                '&:hover': {
                  backgroundColor: '#2d013a'
                },
                textTransform: 'none',
                padding: '10px 24px',
                fontSize: '16px',
                width: '150px',
                height: '45px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              loading={isLoading}
              loadingPosition="center"
              loadingIndicator={<CircularProgress color="secondary" size={24} />}
            >
              Entrar
            </CustomLoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserSignIn;
