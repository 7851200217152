import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Context from '../../Context';
import { TextField, Typography, Box, Alert, CircularProgress } from '@mui/material';
import CustomLoadingButton from '../common/CustomLoadingButton';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const CreateTemplate = () => {
  const context = useContext(Context.Context);
  const [name, setName] = useState('');
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const authUser = context.authenticatedUser;
  let navigate = useNavigate();

  const onChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'name') {
      setName(value);
    }
  };

  const submit = async event => {
    event.preventDefault();
    setIsLoading(true);
    const template = {
      name: name,
      userId: authUser.id
    };

    try {
      const errors = await context.data.createTemplate(
        template,
        authUser.emailAddress,
        authUser.password
      );
      if (errors.length) {
        setErrors(errors);
      } else {
        navigate('/templates');
      }
    } catch (error) {
      console.error(error);
      navigate('/error');
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh', // Garante que ocupe 100% da altura da tela
        backgroundColor: '#f0f0f5', // Ajuste do background
        marginTop: '-64px', // Ajuste para subir e encostar no header
        padding: '0 20px'
      }}
    >
      <Box
        sx={{
          maxWidth: '400px',
          width: '100%',
          padding: '30px',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          textAlign: 'center'
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: '#370144',
            marginBottom: '20px'
          }}
        >
          <NoteAddIcon sx={{ fontSize: '32px', color: '#370144' }} />
          Criar Template
        </Typography>
        {errors.length > 0 && (
          <Alert severity="error" sx={{ marginBottom: '15px', textAlign: 'left' }}>
            {errors.map((error, i) => (
              <li key={i} style={{ listStyleType: 'none', fontSize: '14px' }}>
                {error}
              </li>
            ))}
          </Alert>
        )}
        <form onSubmit={submit}>
          <TextField
            required
            label="Nome do Template"
            variant="outlined"
            fullWidth
            margin="normal"
            name="name"
            value={name}
            onChange={onChange}
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                fontSize: '14px'
              },
              '& .MuiInputLabel-root': {
                fontSize: '14px'
              }
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CustomLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: '#370144',
                '&:hover': {
                  backgroundColor: '#2d013a'
                },
                textTransform: 'none',
                padding: '10px 24px',
                fontSize: '16px',
                width: '100%',
                maxWidth: '150px',
                height: '45px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              loading={isLoading}
              loadingPosition="center"
              loadingIndicator={<CircularProgress color="secondary" size={24} />}
            >
              Criar
            </CustomLoadingButton>
          </Box>
        </form>
      </Box>
    </div>
  );
};

export default CreateTemplate;
