import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, Box, Typography, Button } from '@mui/material';
import Context from '../../Context';
import Loading from '../Loading';
import Example from './Example';

const TemplateDetail = () => {
  const context = useContext(Context.Context);
  const [template, setTemplateDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const authUser = context.authenticatedUser;
  const [isShared, setIsShared] = useState(false);

  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (context.templateId !== id) {
      context.actions.setTemplateId(id);
    }
  }, [id, context.templateId, context.actions]);

  useEffect(() => {
    const controller = new AbortController();
    context.data
      .getTemplate(id)
      .then(response => {
        if (response.id) {
          setTemplateDetail(response);
          setIsShared(response.isShared);
        } else {
          navigate('/notfound');
        }
      })
      .catch(error => {
        console.error('Error fetching and parsing template', error);
        navigate('/error');
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => controller?.abort();
  }, [id, navigate, context.data]);

  const onChange = e => {
    setIsShared(e.target.checked);
    context.data.setIsSharedTemplate(
      id,
      e.target.checked,
      authUser.emailAddress,
      authUser.password
    );
  };

  return isLoading ? (
    <Loading />
  ) : template ? (
    <div className="wrap">
      <Box sx={{ padding: '20px' }}>
        <Box
          className="actions--bar"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: '20px',
            padding: '10px',
            backgroundColor: '#e9e5f0',
            borderRadius: '8px'
          }}
        >
          <Button
            component={Link}
            to="/templates"
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: '#370144',
              '&:hover': {
                backgroundColor: '#2d013a'
              },
              textTransform: 'none',
              marginRight: '10px'
            }}
          >
            Voltar para lista
          </Button>
        </Box>
        <Box
          className="wrap"
          sx={{ padding: '15px', backgroundColor: '#f8f8f8', borderRadius: '8px' }}
        >
          <Typography variant="h5" component="h2" sx={{ mb: 2, color: '#370144' }}>
            {template.name}
          </Typography>
          {authUser.isAdmin && (
            <FormControlLabel
              control={<Checkbox checked={isShared} onChange={onChange} color="primary" />}
              label="Compartilhar com o time"
              sx={{
                color: '#370144',
                fontSize: '14px',
                marginTop: '15px'
              }}
            />
          )}
          <Example />
        </Box>
      </Box>
    </div>
  ) : null;
};

export default TemplateDetail;
