import React, { useContext, useEffect, useState } from 'react';
import Context from '../Context';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from './full-light-horiz.png';
import io from 'socket.io-client';
import config from '../config';
import { Modal } from 'antd';

const url = config.apiBaseUrl;
const socket = io(url.substring(0, url.indexOf('/api')), {
  path: '/api/socket-io',
  transports: ['websocket']
});

const Header = () => {
  const context = useContext(Context.Context);
  const authUser = context.authenticatedUser;
  const [modalAberto, setModalAberto] = useState(false);
  const location = useLocation();

  const handleModalClose = () => {
    setModalAberto(false);
  };

  const handleModalOpen = () => {
    setModalAberto(true);
  };

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Conectado ao servidor Socket.IO');
    });

    socket.on('usuario-atualizado', mensagem => {
      console.log('Recebido do servidor:', mensagem);
    });

    return () => {
      socket.off('connect');
      socket.off('usuario-atualizado');
    };
  }, []);

  const isActiveRoute = path => {
    const regex = new RegExp(`^${path}(/.*)?$`);
    return regex.test(location.pathname);
  };

  return (
    <header
      style={{
        width: '100%',
        padding: '10px 0',
        backgroundColor: '#fff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'fixed',
        top: 0,
        zIndex: 1000
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: '1200px',
          margin: '0 auto',
          padding: '0 20px'
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link to="/">
            <img src={logo} alt="SalesBud" style={{ width: '180px', height: 'auto' }} />
          </Link>
        </h1>
        {authUser && !authUser?.resetPassword && (
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <Link
              to="/"
              style={{
                textDecoration: 'none',
                color: isActiveRoute('/meetings') ? '#ff007a' : '#370144'
              }}
            >
              Reuniões
            </Link>
            <Link
              to="/templates"
              style={{
                textDecoration: 'none',
                color: isActiveRoute('/templates') ? '#ff007a' : '#370144'
              }}
            >
              Templates
            </Link>
            {authUser.isAdmin && (
              <Link
                to="/enablement"
                style={{
                  textDecoration: 'none',
                  color: isActiveRoute('/enablement') ? '#ff007a' : '#370144'
                }}
              >
                Enablement
              </Link>
            )}
            <Link
              to="/integrating"
              style={{
                textDecoration: 'none',
                color: isActiveRoute('/integrating') ? '#ff007a' : '#370144'
              }}
            >
              Integrações
            </Link>
          </div>
        )}
        <nav style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          {authUser && (
            <ul
              style={{
                display: 'flex',
                gap: '15px',
                listStyle: 'none',
                margin: 0,
                padding: 0,
                alignItems: 'center'
              }}
            >
              <li style={{ color: '#370144' }}>
                <div>
                  {authUser.company
                    ? authUser.company.planMeeting - authUser.company.countMeeting
                    : 100 - authUser.countMeeting}{' '}
                  reuniões restantes
                </div>
              </li>
              <button
                onClick={handleModalOpen}
                style={{
                  backgroundColor: '#370144',
                  color: '#fff',
                  border: 'none',
                  padding: '8px 16px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  transition: 'background-color 0.3s ease'
                }}
                onMouseOver={e => (e.target.style.backgroundColor = '#2d013a')}
                onMouseOut={e => (e.target.style.backgroundColor = '#370144')}
              >
                Faça um Tour
              </button>
              <li style={{ display: 'flex', alignItems: 'center' }}>
                <Link
                  to="/signout"
                  style={{
                    textDecoration: 'none',
                    color: '#370144',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    border: '1px solid #370144',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                    fontSize: '14px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  onMouseOver={e => {
                    e.target.style.backgroundColor = '#370144';
                    e.target.style.color = '#fff';
                  }}
                  onMouseOut={e => {
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = '#370144';
                  }}
                >
                  Logout
                </Link>
              </li>
            </ul>
          )}
        </nav>
      </div>
      <Modal
        title="Faça um TOUR"
        visible={modalAberto}
        onCancel={handleModalClose}
        footer={null}
        width="80%"
        style={{ top: 20 }}
        bodyStyle={{ height: '500px' }}
        centered={true}
      >
        <iframe
          title="Tour"
          src="https://app.getdemo.com.br/d/a73c0513-3d5a-4596-a58d-ab6582b685ce"
          frameBorder="0"
          allowFullScreen
          style={{ width: '100%', height: '100%' }}
        ></iframe>
      </Modal>
    </header>
  );
};

export default Header;
