import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Header from './Components/Header';
import Courses from './Components/Courses/Courses';
import CourseDetail from './Components/Courses/CourseDetail';
import UpdateCourse from './Components/Courses/UpdateCourse';
import AuthCalendar from './Components/Meetings/AuthCalendar';
import AuthCalendarOutlook from './Components/Meetings/AuthCalendarOutlook';
import SyncCalendar from './Components/Meetings/SyncCalendar';
import SyncCalendarOutlook from './Components/Meetings/SyncCalendarOutlook';
import Meetings from './Components/Meetings/Meetings';
import MeetingDetail from './Components/Meetings/MeetingDetail';
import UpdateMeeting from './Components/Meetings/UpdateMeeting';
import CreateMeeting from './Components/Meetings/CreateMeeting';
import UserSignIn from './Components/User/UserSignIn';
import UpdatePassword from './Components/User/UpdatePassword';
import UserSignOut from './Components/User/UserSignOut';
import CreateCourse from './Components/Courses/CreateCourse';
import NotFound from './Components/Errors/NotFound';
import Forbidden from './Components/Errors/Forbidden';
import UnhandledError from './Components/Errors/UnhandledError';
import Templates from './Components/Templates/Templates';
import TemplateDetail from './Components/Templates/TemplateDetail';
import UpdateTemplate from './Components/Templates/UpdateTemplate';
import CreateTemplate from './Components/Templates/CreateTemplate';
import Integrations from './Components/Integrations/Integrations';
import Enablement from './Components/Meetings/Enablement';
import PrivateRoute from './PrivateRoute';

function App() {
  const location = useLocation();
  const noPaddingRoutes = ['/update-password', '/signin'];
  const shouldApplyPadding = !noPaddingRoutes.includes(location.pathname);

  return (
    <div id="root">
      <Header />
      <main style={{ paddingTop: shouldApplyPadding ? '100px' : '0' }}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/meetings" />} />
          <Route path="/meetings/authcalendar" element={<PrivateRoute element={AuthCalendar} />} />
          <Route
            path="/meetings/authcalendaroutlook"
            element={<PrivateRoute element={AuthCalendarOutlook} />}
          />
          <Route path="/meetings/synccalendar" element={<PrivateRoute element={SyncCalendar} />} />
          <Route
            path="/meetings/synccalendaroutlook"
            element={<PrivateRoute element={SyncCalendarOutlook} />}
          />
          <Route path="/meetings" element={<PrivateRoute element={Meetings} />} />
          <Route path="/meetings/:id" element={<PrivateRoute element={MeetingDetail} />} />
          <Route path="/meetings/create" element={<PrivateRoute element={CreateMeeting} />} />
          <Route path="/meetings/:id/update" element={<PrivateRoute element={UpdateMeeting} />} />
          <Route path="/integrating" element={<PrivateRoute element={Integrations} />} />
          <Route path="/enablement" element={<PrivateRoute element={Enablement} />} />
          <Route path="/templates" element={<PrivateRoute element={Templates} />} />
          <Route path="/templates/:id" element={<PrivateRoute element={TemplateDetail} />} />
          <Route path="/templates/create" element={<PrivateRoute element={CreateTemplate} />} />
          <Route path="/templates/:id/update" element={<PrivateRoute element={UpdateTemplate} />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/courses/:id" element={<CourseDetail />} />
          <Route path="/courses/:id/update" element={<PrivateRoute element={UpdateCourse} />} />
          <Route path="/signin" element={<UserSignIn />} />
          <Route path="/update-password" element={<PrivateRoute element={UpdatePassword} />} />
          <Route path="/signout" element={<UserSignOut />} />
          <Route path="/courses/create" element={<PrivateRoute element={CreateCourse} />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/forbidden" element={<Forbidden />} />
          <Route path="/error" element={<UnhandledError />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
