import { useMemo, useState, useContext } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Box, Button, IconButton, Tooltip, CircularProgress } from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Context from '../../Context';

const Example = () => {
  const context = useContext(Context.Context);
  const authUser = context.authenticatedUser;
  const [validationErrors, setValidationErrors] = useState({});
  const [savingRowId, setSavingRowId] = useState(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'question',
        header: 'Questão',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.question,
          helperText: validationErrors?.question,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              question: undefined
            })
        },
        muiTableBodyCellEditTextFieldProps: {
          fullWidth: true,
          sx: {
            minWidth: '300px',
            textAlign: 'left'
          }
        },
        cellStyle: {
          justifyContent: 'flex-start'
        }
      }
    ],
    [validationErrors]
  );

  const { mutateAsync: createQuestion } = useCreateQuestion(context, authUser);
  const {
    data: fetchedQuestions = [],
    isError: isLoadingQuestionsError,
    isFetching: isFetchingQuestions,
    isLoading: isLoadingQuestions
  } = useGetQuestions(context, authUser);
  const { mutateAsync: updateQuestion } = useUpdateQuestion(context, authUser);
  const { mutateAsync: deleteQuestion } = useDeleteQuestion(context, authUser);

  const handleCreateQuestion = async ({ values, table }) => {
    const newValidationErrors = validateQuestion(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setSavingRowId('new');
    setValidationErrors({});
    await createQuestion(values);
    table.setCreatingRow(null);
    setSavingRowId(null);
  };

  const handleSaveQuestion = async ({ row, values, table }) => {
    const newValidationErrors = validateQuestion(values);
    if (Object.values(newValidationErrors).some(error => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setSavingRowId(row.id);
    setValidationErrors({});
    values.id = row.original.id;
    await updateQuestion(values);
    table.setEditingRow(null);
    setSavingRowId(null);
  };

  const openDeleteConfirmModal = row => {
    if (window.confirm('Certeza que quer excluir essa questão?')) {
      deleteQuestion(row.original.id);
    }
  };

  const table = useMaterialReactTable({
    enableHiding: false,
    enableColumnActions: false,
    enableFullScreenToggle: false,
    initialState: { density: 'compact' },
    enableDensityToggle: false,
    columns,
    data: fetchedQuestions,
    createDisplayMode: 'row',
    editDisplayMode: 'row',
    enableEditing: true,
    getRowId: row => row.id,
    muiToolbarAlertBannerProps: isLoadingQuestionsError
      ? {
          color: 'error',
          children: 'Error loading data'
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: '500px'
      }
    },
    localization: {
      actions: 'Ações'
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateQuestion,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveQuestion,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)} disabled={savingRowId === row.id}>
            {savingRowId === row.id ? <CircularProgress size={24} /> : <EditIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            color="error"
            onClick={() => openDeleteConfirmModal(row)}
            disabled={savingRowId === row.id}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        variant="contained"
        onClick={() => {
          table.setCreatingRow(true);
        }}
        sx={{
          backgroundColor: '#370144',
          '&:hover': {
            backgroundColor: '#2d013a'
          },
          textTransform: 'none',
          padding: '10px 16px',
          fontSize: '14px'
        }}
      >
        Novo Item
      </Button>
    ),
    state: {
      isLoading: isLoadingQuestions,
      showAlertBanner: isLoadingQuestionsError,
      showProgressBars: isFetchingQuestions
    }
  });

  return <MaterialReactTable table={table} />;
};

function useCreateQuestion(context, authUser) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async question => {
      question.templateId = context.templateId;
      return await context.data.createTemplateQuestions(
        question,
        authUser.emailAddress,
        authUser.password
      );
    },
    onSuccess: data => {
      queryClient.setQueryData(['questions'], (prevQuestions = []) => [...prevQuestions, data]);
    }
  });
}

function useGetQuestions(context, authUser) {
  return useQuery({
    queryKey: ['questions'],
    queryFn: async () => {
      return context.data.getTemplateQuestions(
        context.templateId,
        authUser.emailAddress,
        authUser.password
      );
    },
    refetchOnWindowFocus: false
  });
}

function useUpdateQuestion(context, authUser) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async question => {
      await context.data.updateTemplateQuestions(
        question.id,
        question,
        authUser.emailAddress,
        authUser.password
      );
    },
    onMutate: newQuestionInfo => {
      queryClient.setQueryData(['questions'], (prevQuestions = []) =>
        prevQuestions.map(prevQuestion =>
          prevQuestion.id === newQuestionInfo.id ? newQuestionInfo : prevQuestion
        )
      );
    }
  });
}

function useDeleteQuestion(context, authUser) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async questionId => {
      await context.data.deleteTemplateQuestions(
        questionId,
        authUser.emailAddress,
        authUser.password
      );
    },
    onMutate: questionId => {
      queryClient.setQueryData(['questions'], (prevQuestions = []) =>
        prevQuestions.filter(question => question.id !== questionId)
      );
    }
  });
}

const queryClient = new QueryClient();

const ExampleWithProviders = () => (
  <QueryClientProvider client={queryClient}>
    <Example />
  </QueryClientProvider>
);

export default ExampleWithProviders;

const validateRequired = value => value && value.length > 0;

function validateQuestion(question) {
  return {
    question: !validateRequired(question.question) ? 'Preencher o item' : ''
  };
}
