import React, { useState, useEffect, useContext } from 'react';
import Context from '../../Context';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  CircularProgress,
  IconButton,
  InputAdornment,
  Alert,
  Typography
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import LockIcon from '@mui/icons-material/Lock';
import CustomLoadingButton from '../common/CustomLoadingButton';

const UpdatePassword = () => {
  const context = useContext(Context.Context);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const validatePassword = password => {
    return {
      minLength: password.length >= 8,
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      hasSpecialChar: /[@$!%*?&]/.test(password)
    };
  };

  const passwordValidation = validatePassword(password);
  const allValid = Object.values(passwordValidation).every(Boolean);

  const isButtonDisabled = () => !allValid || password !== confirmPassword;

  useEffect(() => {
    Cookies.remove('authenticatedUser');
  }, []);

  useEffect(() => {
    const username = localStorage.getItem('userEmail');
    if (!username) {
      navigate('/signout');
    }
  }, []);

  const handleSubmit = async e => {
    setError('');
    setIsLoading(true);
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('As senhas não coincidem!');
      setIsLoading(false);
      return;
    }

    if (!allValid) {
      setError('A senha não atende a todos os requisitos.');
      setIsLoading(false);
      return;
    }

    const storedEmail = localStorage.getItem('userEmail');
    if (!storedEmail) {
      navigate('/signout');
    }

    try {
      const result = await context.data.updateUserPassword(storedEmail, password);

      if (result.success) {
        setSuccess('Senha atualizada com sucesso!');
        setTimeout(() => {
          localStorage.removeItem('userEmail');
          navigate('/signout');
        }, 2000);
      } else {
        setError(result.message || 'Erro ao atualizar a senha.');
      }
    } catch (err) {
      setError('Erro ao atualizar a senha.');
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5'
      }}
    >
      <div
        className="form--centered"
        style={{
          maxWidth: '400px',
          width: '100%',
          padding: '20px',
          background: '#fff',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: '#370144',
            marginBottom: '20px'
          }}
        >
          <LockIcon style={{ fontSize: '32px', color: '#370144' }} />
          Atualizar Senha
        </Typography>
        {success && (
          <Alert
            severity="success"
            style={{
              marginBottom: '10px',
              fontSize: '12px',
              padding: '6px',
              textAlign: 'center'
            }}
          >
            {success}
          </Alert>
        )}
        {error && (
          <Alert
            severity="error"
            style={{
              marginBottom: '10px',
              fontSize: '12px',
              padding: '6px',
              textAlign: 'center'
            }}
          >
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <div>
            <TextField
              required
              label="Nova senha"
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={e => setPassword(e.target.value)}
              size="small"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { fontSize: '14px' }
              }}
              InputLabelProps={{
                style: { fontSize: '14px' }
              }}
            />
          </div>
          <div>
            <TextField
              required
              label="Confirme a nova senha"
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              size="small"
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { fontSize: '14px' }
              }}
              InputLabelProps={{
                style: { fontSize: '14px' }
              }}
            />
          </div>
          <div
            style={{ fontSize: '12px', marginTop: '10px', lineHeight: '1.5', textAlign: 'left' }}
          >
            <p className={`no-margin ${passwordValidation.minLength ? 'valid' : 'invalid'}`}>
              - Mínimo de 8 caracteres
            </p>
            <p className={`no-margin ${passwordValidation.hasUpperCase ? 'valid' : 'invalid'}`}>
              - Pelo menos uma letra maiúscula
            </p>
            <p className={`no-margin ${passwordValidation.hasLowerCase ? 'valid' : 'invalid'}`}>
              - Pelo menos uma letra minúscula
            </p>
            <p className={`no-margin ${passwordValidation.hasNumber ? 'valid' : 'invalid'}`}>
              - Pelo menos um número
            </p>
            <p className={`no-margin ${passwordValidation.hasSpecialChar ? 'valid' : 'invalid'}`}>
              - Pelo menos um caractere especial (@, $, !, %, *, ?, &)
            </p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '15px' }}>
            <CustomLoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                backgroundColor: '#370144',
                '&:hover': {
                  backgroundColor: '#2d013a'
                },
                textTransform: 'none',
                padding: '10px 24px',
                fontSize: '16px',
                width: '150px',
                height: '45px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              loading={isLoading}
              loadingPosition="center"
              loadingIndicator={<CircularProgress color="secondary" size={24} />}
              disabled={isButtonDisabled()}
            >
              Salvar
            </CustomLoadingButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePassword;
