import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import Context from './Context';

const PrivateRoute = ({ element: Element }) => {
  const context = useContext(Context.Context);
  const authUser = context.authenticatedUser;

  if (!authUser) {
    return <Navigate to="/signin" />;
  } else if (authUser.resetPassword && window.location.pathname !== '/update-password') {
    return <Navigate to="/update-password" />;
  }

  return <Element />;
};

export default PrivateRoute;
