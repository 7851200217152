import config from './config';

export default class Data {
  /**
   * Function to make Fetch requests to our custom REST API
   * @param {*} path - route or path to API endpoint e.g. /courses, /users
   * @param {*} method - e.g. POST, GET
   * @param {*} body - body of the request (optional)
   * @param {*} requiresAuth - whether the API request requires authentication
   * @param {*} credentials - if API request requires authentication, enter in user's credentials (username/email address and password)
   * @returns {function} Make the Fetch API request
   */
  api(path, method = 'GET', body = null, requiresAuth = false, credentials = null) {
    const url = config.apiBaseUrl + path;

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    };

    if (body !== null) {
      options.body = JSON.stringify(body);
    }

    if (requiresAuth) {
      const encodedCredentials = btoa(`${credentials.username}:${credentials.password}`);
      options.headers['Authorization'] = `Basic ${encodedCredentials}`;
    }
    return fetch(url, options);
  }

  /**
   * Get the user from the database for Sign In
   * @param {String} username - for Authentication, the user's email address acts as the "username"
   * @param {String} password
   * @returns API response if successful
   */
  async getUser(username, password) {
    const response = await this.api(`/users`, 'GET', null, true, { username, password });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else if (response.status === 401) {
      return response.json().then(message => message);
    } else {
      throw new Error();
    }
  }

  async getUsersCompany(username, password) {
    const response = await this.api(`/userscompany`, 'GET', null, true, { username, password });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else if (response.status === 401) {
      return response.json().then(message => message);
    } else {
      throw new Error();
    }
  }

  async getUsersCompanyInc(username, password) {
    const response = await this.api(`/userscompanyinc`, 'GET', null, true, { username, password });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else if (response.status === 401) {
      return response.json().then(message => message);
    } else {
      throw new Error();
    }
  }

  async updateUserPassword(email, password) {
    try {
      const response = await this.api(`/update-password`, 'PUT', { email, password });

      if (response.status === 200) {
        return { success: true };
      } else if (response.status === 400) {
        const errorMessage = await response.json();
        return { success: false, message: errorMessage };
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (error) {
      return { success: false, message: 'Error connecting to the server' };
    }
  }

  /**
   * Create a new user in the database
   * @param {Object} user
   * @returns empty response if successful
   */
  async createUser(user) {
    const response = await this.api('/users', 'POST', user);
    if (response.status === 201) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  /**
   * Get all available templates
   * @returns API response if successful
   */
  async getTemplates(username, password) {
    const response = await this.api('/templates', 'GET', null, true, { username, password });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  async setIsSharedTemplate(id, isShared, username, password) {
    const response = await this.api(`/setissharedtemplate/${id}/${isShared}`, 'PUT', null, true, {
      username,
      password
    });
    if (response.status === 204) {
      return [];
    } else {
      throw new Error();
    }
  }

  /**
   * Get a specific template by id
   * @param {String} id - Meeting ID
   * @returns API response if successful
   */
  async getTemplate(id) {
    const response = await this.api(`/templates/${id}`, 'GET', null, false);
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }
  /**
   * Create a new template
   * @param {Object} template with name
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async createTemplate(template, username, password) {
    const response = await this.api('/templates', 'POST', template, true, { username, password });
    if (response.status === 201) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }
  /**
   * Delete a specific template
   * Only users who are authors of the meetings are authorised to delete the course
   * @param {String} id - template ID
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async deleteTemplate(id, username, password) {
    const response = await this.api(`/templates/${id}`, 'DELETE', null, true, {
      username,
      password
    });
    if (response.status === 204) {
      return [];
    } else if (response.status >= 400 && response.status < 500) {
      return response.json().then(data => {
        return data.error;
      });
    } else {
      throw new Error();
    }
  }

  async setBotName(
    botName,
    username,
    password,
    importMeeting,
    emailMeeting,
    template,
    defaultTemplate
  ) {
    try {
      const payload = {
        botName: botName,
        importMeeting: importMeeting,
        emailMeeting: emailMeeting,
        templateId: template,
        isDefaultTemplate: defaultTemplate
      };
      const response = await this.api(`/botname`, 'POST', payload, true, { username, password });
      if (!response.ok) {
        // Lança um erro se a resposta não for OK (status não está no intervalo 200-299)
        throw new Error(`Erro ao setar botname: ${response.status} ${response.statusText}`);
      }
      return {};
    } catch (error) {
      console.error('Erro:', error);
      throw error; // Re-lança o erro para ser tratado pelo chamador
    }
  }

  async setWebHook(webHook, username, password, apikeyHubspot, apikeyRdStation, apikeyPipedrive) {
    try {
      const payload = {
        webHook: webHook,
        apikeyHubspot: apikeyHubspot,
        apikeyRdStation: apikeyRdStation,
        apikeyPipedrive: apikeyPipedrive
      };
      const response = await this.api(`/webhook`, 'POST', payload, true, { username, password });
      if (!response.ok) {
        // Lança um erro se a resposta não for OK (status não está no intervalo 200-299)
        throw new Error(`Erro ao setar webhook: ${response.status} ${response.statusText}`);
      }
      return {};
    } catch (error) {
      console.error('Erro:', error);
      throw error; // Re-lança o erro para ser tratado pelo chamador
    }
  }

  async getVideoUrl(idBot) {
    try {
      const response = await this.api(`/meetings/videourl/${idBot}`, 'GET', null, false);
      if (!response.ok) {
        // Lança um erro se a resposta não for OK (status não está no intervalo 200-299)
        throw new Error(`Erro ao buscar URL do vídeo: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao obter URL do vídeo:', error);
      throw error; // Re-lança o erro para ser tratado pelo chamador
    }
  }

  async getSyncCalendarOutlook(username, password) {
    //const response = await this.api(`/synccalendaroutlook`, 'GET', null, true, { username, password }, accessToken);
    const response = await this.api(`/synccalendaroutlook`, 'GET', null, true, {
      username,
      password
    });
    //const response = { status : 200, data : code} ;
    if (response.status === 200) {
      return response.json().then(data => data);
      //return response.data;
    } else {
      throw new Error();
    }
  }

  async getSyncCalendar(username, password) {
    const response = await this.api(`/synccalendar`, 'GET', null, true, { username, password });
    //const response = { status : 200, data : code} ;
    if (response.status === 200) {
      return response.json().then(data => data);
      //return response.data;
    } else {
      throw new Error();
    }
  }
  /**
   * Auth Calendar Google
   * @returns API response if successful
   */
  async getCalendar(username, password, code, scope) {
    const encodedCode = encodeURIComponent(code);
    const response = await this.api(`/authcalendar/${encodedCode}`, 'GET', null, true, {
      username,
      password
    });
    //const response = { status : 200, data : code} ;
    if (response.status === 200) {
      return [];
      //return response.data;
    } else {
      throw new Error();
    }
  }

  async getCalendarOutlook(username, password, code, state) {
    const response = await this.api(`/authcalendaroutlook/${code}/${state}`, 'GET', null, true, {
      username,
      password
    });
    //const response = { status : 200, data : code} ;
    if (response.status === 200) {
      return [];
      //return response.data;
    } else {
      throw new Error();
    }
  }

  async setCalendarOutlook(username, password, codeVerifier, state) {
    const encodedCode = encodeURIComponent(codeVerifier);
    const response = await this.api(
      `/setauthcalendaroutlook/${encodedCode}/${state}`,
      'GET',
      null,
      true,
      { username, password }
    );
    //const response = { status : 200, data : code} ;
    if (response.status === 200) {
      return [];
      //return response.data;
    } else {
      throw new Error();
    }
  }

  /**
   * Get all available meetings
   * @returns API response if successful
   */
  async getMeetings(username, password) {
    const response = await this.api('/meetings', 'GET', null, true, { username, password });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  async getMeetingsChat(username, password, meetingid, prompt) {
    const response = await this.api(`/meetingschat/${meetingid}`, 'POST', { prompt }, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  async getEnablementChat(username, password, prompt) {
    const response = await this.api(
      `/enablementchat/${encodeURIComponent(prompt)}`,
      'GET',
      null,
      true,
      { username, password }
    );
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Erro ao obter resposta do chat.');
    }
  }

  async getEnable1(username, password, user, vendedor) {
    const response = await this.api(`/meetingsenable1/${user}/${vendedor}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  async getEnable2(username, password, user, vendedor) {
    const response = await this.api(`/meetingsenable2/${user}/${vendedor}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  async getEnable3(username, password, user, vendedor) {
    const response = await this.api(`/meetingsenable3/${user}/${vendedor}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  async getEnable4(username, password, user, vendedor) {
    const response = await this.api(`/meetingsenable4/${user}/${vendedor}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  async getEnableDS(username, password, user, vendedor) {
    const response = await this.api(`/meetingsenableds/${user}/${vendedor}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }
  async getMeetingsUser(username, password, user) {
    const response = await this.api(`/meetingsuser/${user}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }
  /**
   * Get a specific meeting by id
   * @param {String} id - Meeting ID
   * @returns API response if successful
   */
  async getMeeting(username, password, id) {
    const response = await this.api(`/meetings/${id}`, 'GET', null, true, { username, password });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  /**
   * Get a specific template by id
   * @param {String} id - Template ID
   * @returns API response if successful
   */
  async getTemplateQuestions(id, username, password) {
    const response = await this.api(`/templatequestions/${id}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  /**
   * Get a specific template by id
   * @param {String} id - Template ID
   * @returns API response if successful
   */
  async loadTemplate(username, password, id) {
    const response = await this.api(`/loadtemplate/${id}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  /**
   * Create a new templateQuestion
   * @param {Object} templateQuestion - with title, description, estimated time and materials needed
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async createTemplateQuestions(templateQuestion, username, password) {
    //console.log(templateQuestion);
    const response = await this.api('/templatequestions', 'POST', templateQuestion, true, {
      username,
      password
    });
    if (response.status === 201) {
      return response.json();
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }
  /**
   * Update a particular TemplateQuestions
   * @param {String} id - TemplateQuestions ID
   * @param {Object} tmplateQuestion - with updated title, description, estimated time and materials needed
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async updateTemplateQuestions(id, templateQuestion, username, password) {
    const response = await this.api(`/templatequestions/${id}`, 'PUT', templateQuestion, true, {
      username,
      password
    });
    if (response.status === 204) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }
  /**
   * Get a specific meeting by id
   * @param {String} id - TemplateQuestions ID
   * @returns API response if successful
   */
  async deleteTemplateQuestions(id, username, password) {
    const response = await this.api(`/templatequestions/${id}`, 'DELETE', null, true, {
      username,
      password
    });
    if (response.status === 204) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  /**
   * Get a specific meeting by id
   * @param {String} id - Meeting ID
   * @returns API response if successful
   */
  async getMeetingAnswers(id) {
    const response = await this.api(`/meetinganswers/${id}`, 'GET', null, false);
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }
  /**
   * Create a new meeting
   * @param {Object} meeting - with title, description, estimated time and materials needed
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async createMeeting(meeting, username, password) {
    const response = await this.api('/meetings', 'POST', meeting, true, { username, password });
    if (response.status === 201) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  /**
   * Update a particular Meeting
   * @param {String} id - Meeting ID
   * @param {Object} meeting - with updated title, description, estimated time and materials needed
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async updateMeeting(id, meeting, username, password) {
    const response = await this.api(`/meetings/${id}`, 'PUT', meeting, true, {
      username,
      password
    });
    if (response.status === 204) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  /**
   * Delete a specific meeting
   * Only users who are authors of the meetings are authorised to delete the course
   * @param {String} id - Meeting ID
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async deleteMeeting(id, username, password) {
    const response = await this.api(`/meetings/${id}`, 'DELETE', null, true, {
      username,
      password
    });
    if (response.status === 204) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  async disableMeeting(id, username, password) {
    const response = await this.api(`/meetings/disable/${id}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  async enableMeeting(id, username, password) {
    const response = await this.api(`/meetings/enable/${id}`, 'GET', null, true, {
      username,
      password
    });
    if (response.status === 200) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }
  /**
   * Get all available courses
   * @returns API response if successful
   */
  async getCourses() {
    const response = await this.api('/courses', 'GET', null, false);
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  /**
   * Get a specific course by id
   * @param {String} id - Course ID
   * @returns API response if successful
   */
  async getCourse(id) {
    const response = await this.api(`/courses/${id}`, 'GET', null, false);
    if (response.status === 200) {
      return response.json().then(data => data);
    } else {
      throw new Error();
    }
  }

  /**
   * Create a new course
   * @param {Object} course - with title, description, estimated time and materials needed
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async createCourse(course, username, password) {
    const response = await this.api('/courses', 'POST', course, true, { username, password });
    if (response.status === 201) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  /**
   * Delete a specific course
   * Only users who are authors of the course are authorised to delete the course
   * @param {String} id - Course ID
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async deleteCourse(id, username, password) {
    const response = await this.api(`/courses/${id}`, 'DELETE', null, true, { username, password });
    if (response.status === 204) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }

  /**
   * Update a particular course
   * @param {String} id - Course ID
   * @param {Object} course - with updated title, description, estimated time and materials needed
   * @param {String} username - user's email address
   * @param {String} password
   * @returns empty response if successful
   */
  async updateCourse(id, course, username, password) {
    const response = await this.api(`/courses/${id}`, 'PUT', course, true, { username, password });
    if (response.status === 204) {
      return [];
    } else if (response.status === 400) {
      return response.json().then(data => {
        return data.errors;
      });
    } else {
      throw new Error();
    }
  }
}
