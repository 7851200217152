import React, { useState, useEffect, useRef, useContext } from 'react';
import { Input, Button, Modal, List, Spin } from 'antd';
import { SendOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import Context from '../../Context';
import Loading from '../Loading';
import { Table, Tag, Select } from 'antd';
import Column from 'antd/es/table/Column';

const Enablement = () => {
  const context = useContext(Context.Context);
  const [isLoading, setIsLoading] = useState(true);
  const authUser = context.authenticatedUser;
  const [ds1, setDs1] = useState([]);
  const [ds2, setDs2] = useState([]);
  const [ds3, setDs3] = useState([]);
  const [ds4, setDs4] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [usersCompany, setUsersCompany] = useState([]);
  const [vendedor, setVendedor] = useState(null);
  const [initialMessage, setInitialMessage] = useState('');

  let navigate = useNavigate();

  useEffect(() => {
    context.data
      .getUsersCompanyInc(authUser.emailAddress, authUser.password)
      .then(response => {
        setUsersCompany(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing data', error);
      });
    context.data
      .getEnableDS(authUser.emailAddress, authUser.password, authUser.id, vendedor)
      .then(response => {
        setDataSource(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing enableDS', error);
      });

    context.data
      .getEnable1(authUser.emailAddress, authUser.password, authUser.id, vendedor)
      .then(response => {
        setDs1(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing enable1', error);
      });

    context.data
      .getEnable2(authUser.emailAddress, authUser.password, authUser.id, vendedor)
      .then(response => {
        setDs2(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing enable1', error);
      });

    context.data
      .getEnable3(authUser.emailAddress, authUser.password, authUser.id, vendedor)
      .then(response => {
        setDs3(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing enable3', error);
      });

    context.data
      .getEnable4(authUser.emailAddress, authUser.password, authUser.id, vendedor)
      .then(response => {
        setDs4(response);
      })
      .catch(error => {
        console.error('Error fetching and parsing enable3', error);
      })
      .finally(() => setIsLoading(false));
  }, [authUser, context, vendedor]);

  const [selectedCell, setSelectedCell] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  const handleCellClick = (record, columnKey, columnName) => {
    setSelectedCell({ record, columnKey, columnName });
    setShowMessage(true);
  };

  const handleTextClick = () => {
    setShowMessage(false);
    setSelectedCell(null);
  };

  const col1 = [
    {
      title: 'Vendedor',
      dataIndex: 'vendedor',
      key: 'vendedor',
      render: (text, record) => (
        <Tag
          color="blue"
          onClick={() => handleCellClick(record, 'vendedor', 'Vendedor')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </Tag>
      )
    },
    {
      title: 'Reunião',
      dataIndex: 'reuniao',
      key: 'reuniao',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'reuniao', 'Reunião')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Tempo de fala (cliente)',
      dataIndex: 'tempofalacli',
      key: 'tempofalacli',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'tempofalacli', 'Tempo de fala(cliente)')}
          style={{ cursor: 'pointer' }}
        >
          {`${text}%`}
        </span>
      )
    },
    {
      title: 'Tempo de fala (vendedor)',
      dataIndex: 'tempofalavend',
      key: 'tempofalavend',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'tempofalavend', 'Tempo de fala(Vendedor)')}
          style={{ cursor: 'pointer' }}
        >
          {`${text}%`}
        </span>
      )
    }
  ];
  const col2 = [
    {
      title: 'Tipo de reunião',
      dataIndex: 'tiporeuniao',
      key: 'tiporeuniao',
      render: (text, record) => (
        <Tag
          color="blue"
          onClick={() => handleCellClick(record, 'tiporeuniao', 'Tipo de reunião')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </Tag>
      )
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      key: 'quantidade',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'quantidade', 'Quantidade')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Percentual',
      dataIndex: 'percentual',
      key: 'percentual',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'percentual', 'Percentual')}
          style={{ cursor: 'pointer' }}
        >
          {`${text}%`}
        </span>
      )
    }
  ];
  const col3 = [
    {
      title: 'Vendedor',
      dataIndex: 'vendedor',
      key: 'vendedor',
      render: (text, record) => (
        <Tag
          color="blue"
          onClick={() => handleCellClick(record, 'vendedor', 'Vendedor')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </Tag>
      )
    },
    {
      title: 'Reunião',
      dataIndex: 'reuniao',
      key: 'reuniao',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'reuniao', 'Reunião')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Próximos passos',
      dataIndex: 'proxpasso',
      key: 'proxpasso',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'proxpasso', 'Próximos passos')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    }
  ];

  const col4 = [
    {
      title: 'Vendedor',
      dataIndex: 'vendedor',
      key: 'vendedor',
      render: (text, record) => (
        <Tag
          color="blue"
          onClick={() => handleCellClick(record, 'vendedor', 'Vendedor')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </Tag>
      )
    },
    {
      title: 'Reunião',
      dataIndex: 'reuniao',
      key: 'reuniao',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'reuniao', 'Reunião')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Engajamento do cliente na reunião',
      dataIndex: 'engajacli',
      key: 'engajacli',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'engajacli', 'Engajamento do cliente na reunião')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Empolgação do cliente com produto',
      dataIndex: 'empolgacli',
      key: 'empolgacli',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'empolgacli', 'Empolgação do cliente com produto')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Profundidade das perguntas do vendedor',
      dataIndex: 'profunperg',
      key: 'profunperg',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() =>
            handleCellClick(record, 'profunperg', 'Profundidade das perguntas do vendedor')
          }
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Nível de objeções levantadas',
      dataIndex: 'nivelobje',
      key: 'nivelobje',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'nivelobje', 'Nível de objeções levantadas')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Nível de educação do cliente',
      dataIndex: 'niveledu',
      key: 'niveledu',
      render: (text, record) => (
        <span
          color="blue"
          onClick={() => handleCellClick(record, 'niveledu', 'Nível de educação do cliente')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    }
  ];

  const columns = [
    {
      title: 'Vendedor',
      dataIndex: 'vendedor',
      key: 'vendedor',
      width: '10%',
      render: (text, record) => (
        <Tag
          color="blue"
          onClick={() => handleCellClick(record, 'vendedor', 'Vendedor')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </Tag>
      )
    },
    {
      title: 'Comunicação',
      dataIndex: 'comunicacao',
      key: 'comunicacao',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'comunicacao', 'Comunicação')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Conhecimento de produtos',
      dataIndex: 'conhecimento',
      key: 'conhecimento',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'conhecimento', 'Conhecimento de produtos')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Habilidade de negociação',
      dataIndex: 'habilidadeNegociacao',
      key: 'habilidadeNegociacao',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() =>
            handleCellClick(record, 'habilidadeNegociacao', 'Habilidade de negociação')
          }
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Empatia',
      dataIndex: 'empatia',
      key: 'empatia',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'empatia', 'Empatia')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Proatividade',
      dataIndex: 'proatividade',
      key: 'proatividade',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'proatividade', 'Proatividade')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Persistência',
      dataIndex: 'persistencia',
      key: 'persistencia',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'persistencia', 'Persistência')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Gestão de tempo',
      dataIndex: 'gestaoTempo',
      key: 'gestaoTempo',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'gestaoTempo', 'Gestão de tempo')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Habilidade de fechamento',
      dataIndex: 'habilidadeFechamento',
      key: 'habilidadeFechamento',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() =>
            handleCellClick(record, 'habilidadeFechamento', 'Habilidade de fechamento')
          }
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    },
    {
      title: 'Adaptabilidade',
      dataIndex: 'adaptabilidade',
      key: 'adaptabilidade',
      width: '10%',
      render: (text, record) => (
        <span
          onClick={() => handleCellClick(record, 'adaptabilidade', 'Adaptabilidade')}
          style={{ cursor: 'pointer' }}
        >
          {text}
        </span>
      )
    }
  ];
  const onChangeSelect = value => {
    console.log(`selected ${value}`);
    setVendedor(value);
    console.log(`Vendedor ${vendedor}`);
  };

  const openPrompt = message => {
    setShowMessage(true);
    setInitialMessage(message);
  };

  const closePrompt = () => {
    setShowMessage(false);
  };

  const Prompt = ({ visible, onClose, initialMessage }) => {
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);

    useEffect(() => {
      if (visible && initialMessage) {
        startChat(initialMessage);
      }
      if (visible) {
        inputRef.current.focus();
      }
    }, [visible, initialMessage]);

    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const startChat = async message => {
      setLoading(true);
      try {
        const response = await context.data.getEnablementChat(
          authUser.emailAddress,
          authUser.password,
          message
        );
        setMessages([{ text: response.result, from: 'bot' }]);
      } catch {
        setMessages([{ text: 'Ocorreu um erro, tente novamente por favor.', from: 'bot' }]);
      } finally {
        setLoading(false);
      }
    };

    const handleSend = async () => {
      if (!inputValue.trim()) return;

      const newMessage = { text: inputValue, from: 'user' };
      setMessages(prev => [...prev, newMessage]);
      setInputValue('');
      setLoading(true);

      try {
        const response = await context.data.getEnablementChat(
          authUser.emailAddress,
          authUser.password,
          inputValue
        );
        setMessages(prev => [...prev, { text: response.result, from: 'bot' }]);
      } catch {
        setMessages(prev => [
          ...prev,
          { text: 'Ocorreu um erro, tente novamente por favor.', from: 'bot' }
        ]);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        title="Converse com o BUD!"
        visible={visible}
        onCancel={onClose}
        footer={null}
        width={800}
        bodyStyle={{ padding: 0 }}
        wrapClassName="custom-modal"
        onClick={e => e.target === e.currentTarget && onClose()}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '60vh', position: 'relative' }}
        >
          {loading && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 10
              }}
            >
              <Spin indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />} />
            </div>
          )}
          <div style={{ flex: 1, padding: '18px', overflowY: 'auto' }}>
            {messages.length > 0 && (
              <List
                dataSource={messages}
                renderItem={(message, index) => (
                  <List.Item
                    key={index}
                    style={{ textAlign: message.from === 'user' ? 'right' : 'left' }}
                  >
                    <List.Item.Meta
                      description={<div dangerouslySetInnerHTML={{ __html: message.text }} />}
                    />
                  </List.Item>
                )}
              />
            )}
            <div ref={messagesEndRef} />
          </div>
          <div
            style={{
              padding: '16px',
              borderTop: '1px solid #f0f0f0',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Input
              ref={inputRef}
              placeholder="Digite seu prompt..."
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              onPressEnter={handleSend}
              suffix={
                <SendOutlined
                  onClick={handleSend}
                  style={{
                    cursor: 'pointer',
                    color: inputValue.trim() ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.25)'
                  }}
                />
              }
              disabled={loading}
            />
          </div>
        </div>
      </Modal>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div>
      {showMessage ? (
        <Prompt visible={showMessage} onClose={closePrompt} initialMessage={initialMessage} />
      ) : (
        <div className="wrap">
          Filtro por vendedor:
          <Select
            placeholder="Selecione um vendedor"
            optionFilterProp="children"
            onChange={onChangeSelect}
            options={usersCompany.map(user => ({
              value: user.id,
              label: `${user.firstName} ${user.lastName}`
            }))}
            style={{ width: 200, marginLeft: 10, marginBottom: '15px' }}
          />
          <Table
            dataSource={dataSource}
            columns={columns.map((column, columnIndex) => ({
              ...column,
              onCell: record => ({
                style: record[column.dataIndex] !== 'Alto' ? { cursor: 'not-allowed' } : {},
                onClick: () => {
                  const columnName = columns[columnIndex]?.dataIndex || 'Coluna não identificada';
                  const value = record[columnName];
                  setSelectedCell({ record, columnName });
                  if (value === 'Baixo' || value === 'Médio') {
                    openPrompt(
                      `Por que o vendedor ${record.vendedor} recebeu a nota "${value}" nesta reunião, especificamente no quesito de "${columnName}"? Forneça uma explicação detalhada do desempenho dele neste aspecto, incluindo exemplos específicos que justifiquem a nota. Em seguida, ofereça um feedback estruturado, destacando áreas de melhoria e sugerindo ações concretas que o vendedor pode tomar para aprimorar sua performance no futuro. Certifique-se de abordar tanto os pontos fortes quanto os fracos do vendedor no quesito de "${columnName}". Ao trazer o resultado formate a resposta, principalmente o texto de ${columnName} para melhor visualização.`
                    );
                  } else {
                    closePrompt();
                  }
                }
              })
            }))}
            rowClassName={record =>
              selectedCell && selectedCell.record.key === record.key ? 'highlight-row' : ''
            }
            bordered
          />
          <br />
          <div className="table-container">
            <div className="table-wrapper">
              <Table
                dataSource={ds1}
                columns={col1.map(column => ({
                  ...column
                }))}
                rowClassName={record =>
                  selectedCell && selectedCell.record.key === record.key ? 'highlight-row' : ''
                }
                onRow={() => ({
                  onClick: closePrompt
                })}
                bordered
              />
            </div>
            <div className="table-wrapper">
              <Table
                dataSource={ds2}
                columns={col2.map(column => ({
                  ...column
                }))}
                rowClassName={record =>
                  selectedCell && selectedCell.record.key === record.key ? 'highlight-row' : ''
                }
                onRow={() => ({
                  onClick: closePrompt
                })}
                bordered
              />
            </div>
          </div>
          <br />
          <Table
            dataSource={ds3}
            columns={col3.map(column => ({
              ...column
            }))}
            rowClassName={record =>
              selectedCell && selectedCell.record.key === record.key ? 'highlight-row' : ''
            }
            onRow={() => ({
              onClick: closePrompt
            })}
            bordered
          />
          <br />
          <Table
            dataSource={ds4}
            columns={col4.map(column => ({
              ...column
            }))}
            rowClassName={record =>
              selectedCell && selectedCell.record.key === record.key ? 'highlight-row' : ''
            }
            onRow={() => ({
              onClick: closePrompt
            })}
            bordered
          />
        </div>
      )}
    </div>
  );
};

export default Enablement;
